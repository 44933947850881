import React from "react";
import { Article } from "./Article";

export const capitainBluebearImage =
  "https://vignette.wikia.nocookie.net/zamonien/images/2/2a/Blaub%C3%A4r_in_der_Walnussschale.png/revision/latest?cb=20160124161219&path-prefix=de";

export const CapitainBluebear = () => {
  return (
    <Article
      title="The 13 1/2 Lives of Capitain Bluebear"
      image={capitainBluebearImage}
    >
      A life usually begins with birth - mine does not. At least I do not know
      how I came into life. I could - theoretically - have been born from the
      foam of a wave or grown in a shell like a pearl. I might have fallen from
      the sky, in a shooting star, but all that is certain is that I was
      abandoned as a foundling in the middle of the ocean. My first memory is
      that I was floating in rough seas, naked and alone in a walnut shell,
      because I was originally very, very small. I still remember a sound. It
      was a very big sound. No matter how small you are, you tend to
      overestimate things, but today I know that it was indeed the biggest sound
      in the world, generated by the most monstrous, dangerous and loudest water
      vortex in the seven seas - I had no idea that it was the dreaded Malmstrom
      I was looking at in my bowl. For me it was only a huge gurgling. Probably
      I thought (if you could call it thinking) that it was probably the most
      natural state to drift naked in a nutshell on the open sea towards a
      deafening roar.
      <p>Excerpt from The 13 1/2 Lives of Capitain Bluebear by Walter Moers.</p>
    </Article>
  );
};
